import React, { useContext } from 'react';
import { TProps } from '@shared/types';
import { cn } from '@shadcn/lib/utils';
import { Carousel, CarouselContent, CarouselItem } from '@shadcn/ui/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { PromotionContext } from '@shared/components/Promotion/container/Promotion';
import _ from 'lodash';
import Link from 'next/link';
import { NextAssetImage } from '@ui/NextAssetImage';
import { TAsset } from '@shared/types/asset';
import { AspectRatio } from '@shadcn/ui/aspect-ratio';

type LsBannerProps = TProps<{
  autoplayDelay?: number;
}>;

export const LsBanner = ({
  autoplayDelay = 5000,
  children,
  className,
}: LsBannerProps) => {
  return (
    <div className={cn('', className)}>
      <Carousel
        plugins={[
          Autoplay({
            delay: autoplayDelay,
          }),
        ]}
      >
        <CarouselContent>{children}</CarouselContent>
      </Carousel>
    </div>
  );
};

type LsBannerItemProps = TProps<{}>;

export const LsBannerItem = ({ children, className }: LsBannerItemProps) => {
  return <CarouselItem className={cn('', className)}>{children}</CarouselItem>;
};

type LsBannerItemPromotionProps = TProps<{}>;

export const LsBannerItemPromotion = ({
  className,
}: LsBannerItemPromotionProps) => {
  const ctx = useContext(PromotionContext);
  const assets = _.get(ctx, ['data', 'meta_data', 'assets'], []) as TAsset[];

  if (_.isEmpty(assets)) return null;
  if (assets.length !== 2) return null;

  const asset_slider_full = _.head(
    assets.filter((asset) => asset['asset_type'] === 'slider_full'),
  );
  const asset_slider_slim = _.head(
    assets.filter((asset) => asset['asset_type'] === 'slider_slim'),
  );
  if (_.isNil(asset_slider_full) || _.isNil(asset_slider_slim)) return null;

  asset_slider_slim.caption = _.defaultTo(
    _.get(asset_slider_slim, 'caption', null),
    'Promotion',
  );
  asset_slider_full.caption = _.defaultTo(
    _.get(asset_slider_full, 'caption', null),
    'Promotion',
  );

  const name = _.get(ctx, ['data', 'name'], '') as string;
  const href = `/promotion?name=${name}`;

  return (
    <LsBannerItemAssets
      fullAsset={asset_slider_full}
      slimAsset={asset_slider_slim}
      href={href}
    />
  );
};

type LsBannerItemPublicImageProps = TProps<{
  fullAsset: TAsset;
  slimAsset: TAsset;
  href?: string;
}>;

export const LsBannerItemAssets = ({
  className,
  href = '#',
  fullAsset,
  slimAsset,
}: LsBannerItemPublicImageProps) => {
  return (
    <CarouselItem className={cn('', className)}>
      <Link href={href} className={cn('')}>
        <div className={cn('hidden md:block')}>
          <NextAssetImage
            asset={fullAsset}
            width={1280}
            height={353}
            loading={'eager'}
            priority
            placeholder={undefined}
            className={undefined}
          />
        </div>
        <div className={cn('block md:hidden')}>
          <NextAssetImage
            asset={slimAsset}
            width={600}
            height={250}
            loading={'eager'}
            priority
            placeholder={undefined}
            className={undefined}
          />
        </div>
      </Link>
    </CarouselItem>
  );
};
