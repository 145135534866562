import {
	LsBanner,
	LsBannerItemAssets,
	LsBannerItemPromotion,
} from '@lsoft/shared/components/ui/LsBanner';
import {
	PromotionsContainer,
	PromotionsDataIterator,
} from '@lsoft/shared/components/Promotion/container/Promotions';
import { PromotionContainer } from '@lsoft/shared/components/Promotion/container/Promotion';
import React from 'react';

const grandOpeningAssets = {
	// 1280x353
	fullAsset: {
		asset_type: 'slider_full',
		url: {
			DE: '/images/banner/reopening.de.png',
			EN: '/images/banner/reopening.en.png',
			FR: '/images/banner/reopening.fr.png',
		},
		url_type: 'RELATIVE',
		caption: {
			DE: '/images/banner/reopening.de.png',
			EN: '/images/banner/reopening.en.png',
			FR: '/images/banner/reopening.fr.png',
		},
		active: true,
	},
	// 600x250
	slimAsset: {
		asset_type: 'slider_slim',
		url: {
			DE: '/images/banner/reopening_mobile.de.png',
			EN: '/images/banner/reopening_mobile.en.png',
			FR: '/images/banner/reopening_mobile.fr.png',
		},
		url_type: 'RELATIVE',
		caption: {
			DE: '/images/banner/reopening_mobile.de.png',
			EN: '/images/banner/reopening_mobile.en.png',
			FR: '/images/banner/reopening_mobile.fr.png',
		},
		active: true,
	},
};

export const Banner = () => {
	return (
		<LsBanner className={'w-full'}>
			<PromotionsContainer active={true} without_empty>
				<PromotionsDataIterator>
					<PromotionContainer>
						<LsBannerItemPromotion />
					</PromotionContainer>
				</PromotionsDataIterator>
			</PromotionsContainer>
		</LsBanner>
	);
};
